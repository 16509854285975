import React, { Component } from 'react';
import './App.css';

import Company from './Company';
import Landing from './Landing';
import Loader from './Loader';

class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      error: false,
      landing: false
    }
  }

  componentWillMount(){
  let path = window.location.pathname.substring(1);
  path = path.toLowerCase();

  if (path === '' || path === ' '){
    this.setState({landing: true});
  }
  path = path.replace('.html', '');
  path = `data/${path}.json`; // `api/company/${path}`;
  
  fetch(path)
  .then(response => response.json())
  .then((data) => {
    if (data === null){
      this.setState({ error: true });
    }
    this.setState({ id: data.key, company: data.value });
  })
  .catch((err) => {
    console.log("error", err);
    this.setState({error: true});
  });

  }
  
  render() {
    if (this.state.error === true || this.state.landing === true) {
      console.log("landing");
      return <Landing />
    }
    if (!this.state.hasOwnProperty('company')) {//react renders the page before the fetch is done; this keeps exiting until the data is ready
      console.log('no data')
      return <Loader />
    }

    const company = this.state.company;
    // NOTE: this can result in a white link color, which we don't want
    // TODO: make a special case for white or just always use the default
    // const style= `a { color: ${company.webpage.headerBackgroundColor}; }`
    
    return (
      <div className="App">
      {/* <style>{style}</style>	 */}

      <Company 
        id={this.state.id}
        company={company} />

      </div>
    );
  }
}

export default App;
