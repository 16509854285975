import React, { Component } from 'react';

class Loader extends Component {

	render(){
		let loader = `<div class="lds-css ng-scope"><div style="width:100%;height:100%" class="lds-dual-ring"><div></div></div><style type="text/css">@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.lds-dual-ring {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 30vh
}
.lds-dual-ring div {
  position: absolute;
  width: 160px;
  height: 160px;
  top: 20px;
  left: 20px;
  border-radius: 50%;
  border: 8px solid #000;
  border-color: #222 transparent #222 transparent;
  -webkit-animation: lds-dual-ring 1.6s linear infinite;
  animation: lds-dual-ring 1.6s linear infinite;
}
.lds-dual-ring {
  width: 200px !important;
  height: 200px !important;
  -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
  transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
}
</style></div>`;


		return(
			<div dangerouslySetInnerHTML={{__html: loader}} />
					)
		
	}

}

export default Loader;
