import { Component } from 'react';

class Landing extends Component {
  componentWillMount() { }

  render() {
    return window.location.replace('https://www.benefitsbasket.com/home/index.html');
  }
}

export default Landing;
