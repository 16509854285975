import React, { Component } from 'react';
import './Card.css';

class Card extends Component {
  
  render() {
    this.props.fields = this.props.fields || [];
    
    let style = {
      borderBottom: `3px solid ${this.props.color}`
    }

    let fullUrl = this.props.url;
    if (!fullUrl.startsWith('https://') && !fullUrl.startsWith('http://')) {
      fullUrl = 'http://'+fullUrl;
    }

    return(
      <article key={this.props.key} className="benefit-card">		
        <h2 style={style}>{this.props.title}</h2>
        <p className="title">Provider</p>
        <img src={this.props.logo} className="logo"/>
        <div className="row carrier-info">
          <div className=" col-sm">
            <p className="title">Website</p>
            <a href={fullUrl}>{this.props.url}</a>
          </div>
          <div className=" col-sm">
            <p className="title">Phone</p>
            <a href={ `tel:${this.props.phone}`}>{this.props.phone || "-"}</a>
          </div>
          {this.props.fields.map((field) => {

            return (<div className=" col-sm">
              <p className="title">{field.name}</p>
              <p>{field.value}</p>
            </div>)				

          })}
          
        </div>
      </article>
    );
  }
}

export default Card;
