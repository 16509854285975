import React, { Component } from 'react';
import "./Footer.css";

class Footer extends Component {
	render(){
		let style = {};
		if (this.props.background && this.props.color){
			style = {
				backgroundColor: this.props.background,
				color: this.props.color
			}
		}
		return(
				
			<footer className="benefit-footer text-center" style={style}>
				<div className="container">
					<p style={style}>
						Benefits Basket © 2019 &nbsp;&nbsp;|&nbsp;<a href="tel:3852302700">(385) 230-2700</a>
					</p>
				</div>
			</footer>
		
		)
	
	}

}

export default Footer;
