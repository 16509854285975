import React, { Component } from 'react';

import './Company.css';

import Card from './Card';
import Footer from './Footer';

class Company extends Component {

  componentDidMount() {
    document.title = this.props.company.name;
  }

  render() {
    const company = this.props.company;
    const id = this.props.id;

    if (company === 'undefined') {
			return null
    }
    		
		let headerStyle = {
			backgroundColor: company.webpage.headerBackgroundColor,
			color: company.pass.textColor 
    }
    let passUrl = `https://www.benefitsbasket.com/passes/${id}.pkpass`;
    let androidPassUrl = `https://walletpass.io/?u=${passUrl}`;

    return (
      <div>
      <header className="benefit-header" style={headerStyle}>
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <img src={company.webpage.headerImage} />
            </div>
            <div className="col-sm-9 vert-align">
              <h1 className="title">{company.title}</h1>
            </div>	
          </div>	
          <br />
          
        </div>
        <div className="benefit-subheader">
          <div className="container">
          <div className="row justify-content-end">
              <div className="col-md-3">
                <a href={passUrl}><img alt="add to apple wallet" src="https://developer.apple.com/wallet/images/add-to-apple-wallet.svg" /></a>
              </div>
              <div className="col-md-3">
                <a href={androidPassUrl}><img alt="add to walletpass" src="https://www.walletpasses.io/badges/badge_web_black_en@2x.png" /></a>
              </div>
            </div>
          </div>	
        </div>
      </header>

      <div className="container">		
        {company.carriers.map((card, i) => {
          return (
            <Card title={card.product} 
              logo={card.icon}	
              url={card.url}
              phone={card.phone}
              fields={card.fields}
              color={company.webpage.headerBackgroundColor}
              key={i}
              />
          )
        })}
		  </div>
      
      <Footer />
      </div>
    )
  }
}

export default Company;